import React from "react";
import { RouteComponentProps } from "react-router-dom";
import "./Course.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import {
  dateToFullStr,
  firebaseDateToDateObj,
  priceToStr,
} from "../../functions/utils";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import Title from "../../components/Title";
import SingleCourseElementClub from "../../components/SingleCourseElementClub";
import BlockCourseElementClub from "../../components/BlockCourseElementClub";
import InfinityCourseElementClub from "../../components/InfinityCourseElementClub";
import SearchInput from "../../components/SearchInput";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
  }>;

export default class Course extends React.Component<
  ComposedProps,
  {
    clubId: string;
    coursesLoaded: boolean;
    courses: Array<any>;
    coursesSearch: Array<any>;
    showJoinModal: boolean;
    joinModalLoading: boolean;
    joinModalMsg: null | string;
    joinModalType: "error" | "success";
    showRequestedModal: boolean;
    requestedModalLoading: boolean;
    requestedModalMsg: null | string;
    requestedModalType: "error" | "success";
    courseSearch: string;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      coursesLoaded: false,
      courses: [],
      coursesSearch: [],
      showJoinModal: false,
      joinModalLoading: false,
      joinModalMsg: null,
      joinModalType: "error",
      showRequestedModal: false,
      requestedModalLoading: false,
      requestedModalMsg: null,
      requestedModalType: "error",
      courseSearch: "",
    };
  }

  componentDidMount = () => {
    this.requestCourses();
  };

  requestCourses = () => {
    fetchRetry(
      "getCoursesOfClub",
      {
        clubId: this.state.clubId,
        limit: 50,
        onlyFuture: true,
      },
      1,
      5
    )
      .then(this.handleCoursesSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCoursesSuccess = ({ data }: any) => {
    this.setState({ coursesLoaded: true });
    if (data.success) {
      const courses = data.data;
      courses.sort((a: any, b: any) =>
        a.startTime._seconds > b.startTime._seconds ? 1 : -1
      );

      this.setState({ courses, coursesSearch: courses });
    }
  };

  handleChangeCourseSearch = (val: string) => {
    const searchTerm = val.toLowerCase();
    const coursesSearch: Array<any> = this.state.courses.filter(
      (course: any) => {
        return course.title.toLowerCase().includes(searchTerm);
      }
    );
    this.setState({ courseSearch: val, coursesSearch });
  };

  render() {
    if (!true) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <Title title="Kurse" />
            <SearchInput
              name="course-search-input"
              placeholder="Kurssuche"
              value={this.state.courseSearch}
              onChange={this.handleChangeCourseSearch}
              className="no-margin"
            />
            {!this.state.coursesLoaded ? (
              <>
                <div className="loading-container">
                  <HashLoader color={"#c31924"} size={100} loading={true} />
                </div>
              </>
            ) : (
              <>
                {this.state.courses.length === 0 &&
                  this.state.courseSearch === "" && (
                    <div className="no-courses-container">
                      In diesem Bereich gibts es aktuell keine Kurse.
                    </div>
                  )}
                {this.state.courseSearch !== "" &&
                  this.state.coursesSearch.length === 0 && (
                    <div className="no-courses-container">
                      Mit diesem Filter gibt es aktuell keine Kurse.
                    </div>
                  )}
                {this.state.coursesSearch.map((course: any, index: number) => (
                  <>
                    {course.courseType === "single-course" && (
                      <SingleCourseElementClub
                        clubId={this.state.clubId}
                        branchId={course.branchId}
                        courseId={course.id}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        where={course.location.name}
                        freePlaces={
                          course.testmode
                            ? course.freePlacesTest
                            : course.freePlaces
                        }
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        key={course.id}
                        bookableFrom={firebaseDateToDateObj(
                          course.bookableDate
                        )}
                      />
                    )}
                    {course.courseType === "block-course" && (
                      <BlockCourseElementClub
                        clubId={this.state.clubId}
                        branchId={course.branchId}
                        courseId={course.id}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        courses={course.courses}
                        freePlaces={
                          course.testmode
                            ? course.freePlacesTest
                            : course.freePlaces
                        }
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        key={course.id}
                        bookableFrom={firebaseDateToDateObj(
                          course.bookableDate
                        )}
                      />
                    )}
                    {course.courseType === "infinity-course" && (
                      <InfinityCourseElementClub
                        clubId={this.state.clubId}
                        branchId={course.branchId}
                        courseId={course.id}
                        title={course.title}
                        description={course.description}
                        when={dateToFullStr(
                          firebaseDateToDateObj(course.startTime)
                        )}
                        where={course.location?.name}
                        weekDay={course.weekDay}
                        freePlaces={
                          course.testmode
                            ? course.freePlacesTest
                            : course.freePlaces
                        }
                        price={priceToStr(course.price)}
                        imgUrl={course.imgLowRes}
                        key={course.id}
                        bookableFrom={firebaseDateToDateObj(
                          course.bookableDate
                        )}
                      />
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
