import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import "./SingleCource.css";
import "../../components/CourseElement.css";
import "../../App.css";
import HashLoader from "react-spinners/HashLoader";
import {
  addDayToDate,
  calcSubscription,
  dateDurationToFullStr,
  dateToFullStr,
  firebaseDateToDateObj,
  priceToStr,
  reformatDate,
  strToDate,
} from "../../functions/utils";
import SecButton from "../../components/SecButton";
import BackElement from "../../components/BackElement";
import { fetchRetry } from "../../functions/request";
import { MainContext } from "../../contexts/MainContext";
import SubTitle from "../../components/SubTitle";
import Input from "../../components/Input";
import ErrorMessage from "../../components/ErrorMessage";
import { weekDays } from "../../services/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TestMode from "../../components/TestMode";
import Title from "../../components/Title";
import CourseTerms from "../../components/CourseTerms";

type Props = {};
type ComposedProps = Props &
  RouteComponentProps<{
    clubId: string;
    branchId: string;
    courseId: string;
  }>;

export default class SingleCourse extends React.Component<
  ComposedProps,
  {
    clubId: string;
    branchId: string;
    courseId: string;
    redirectTo: string;
    branchLoaded: boolean;
    courseLoaded: boolean;
    bookCourseLoading: boolean;
    branch: any;
    course: any;
    redirect: string | null;
    email: string;
    fName: string;
    lName: string;
    phoneNumber: string;
    street: string;
    houseNumber: string;
    zipcode: string;
    city: string;
    birthDate: string;
    change: boolean;
    errorMsg: null | string;
    termsAccepted: boolean;
  }
> {
  static contextType = MainContext;
  constructor(props: ComposedProps) {
    super(props);
    this.state = {
      clubId: this.props.match.params.clubId,
      branchId: this.props.match.params.branchId,
      courseId: this.props.match.params.courseId,
      redirectTo: window.location.href.split("/")[5],
      branchLoaded: false,
      courseLoaded: false,
      bookCourseLoading: false,
      branch: {},
      course: {},
      redirect: null,
      email: "",
      fName: "",
      lName: "",
      phoneNumber: "",
      street: "",
      houseNumber: "",
      zipcode: "",
      city: "",
      birthDate: "",
      change: false,
      errorMsg: null,
      termsAccepted: false,
    };
  }

  componentDidMount = () => {
    this.requestBranch();
    this.requestCourse();
  };

  requestBranch = () => {
    fetchRetry(
      "getBranchFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
      },
      1,
      5
    )
      .then(this.handleBranch)
      .catch(this.handleError);
  };

  handleBranch = ({ data }: any) => {
    this.setState({ branchLoaded: true });
    if (data.success) {
      const branch = data.data;
      this.setState({
        branch,
      });
    }
  };

  requestCourse = () => {
    fetchRetry(
      "getCourseFromId",
      {
        clubId: this.state.clubId,
        branchId: this.state.branchId,
        courseId: this.state.courseId,
      },
      1,
      5
    )
      .then(this.handleCourseSuccess)
      .catch(this.handleError);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  handleCourseSuccess = ({ data }: any) => {
    if (data.success) {
      this.setState({ courseLoaded: true, course: data.data[0] });
    }
  };

  bookCourse = () => {
    if (this.state.termsAccepted) {
      const redirectUrl = `${window.location.origin}/club/${this.state.clubId}/branch/${this.state.branchId}/courseBooking/`;
      if (this.context.userLoaded) {
        if (!this.context.user) {
          if (!this.state.bookCourseLoading) {
            this.setState({ bookCourseLoading: true });
            fetchRetry(
              "courseBook",
              {
                clubId: this.state.clubId,
                branchId: this.state.branchId,
                courseId: this.state.courseId,
                price: this.state.course.price,
                redirectUrl: redirectUrl,
                email: this.state.email,
                fName: this.state.fName,
                lName: this.state.lName,
                birthDate: reformatDate(this.state.birthDate),
                address: {
                  street: this.state.street,
                  houseNumber: this.state.houseNumber,
                  zipcode: this.state.zipcode,
                  city: this.state.city,
                },
                phoneNumber: this.state.phoneNumber,
                cancellationDays: this.state.course.cancellationDays,
              },
              1,
              5
            )
              .then(this.handleBookCourseSuccess)
              .catch(this.handleError);
          }
        } else {
          if (!this.state.bookCourseLoading) {
            this.setState({ bookCourseLoading: true });
            fetchRetry(
              "courseBook",
              {
                clubId: this.state.clubId,
                branchId: this.state.branchId,
                courseId: this.state.courseId,
                price: this.state.course.price,
                redirectUrl: redirectUrl,
                cancellationDays: this.state.course.cancellationDays,
              },
              1,
              5
            )
              .then(this.handleBookCourseSuccess)
              .catch(this.handleError);
          }
        }
      }
    } else {
      this.setState({ errorMsg: "Bitte akzeptiere die AGBs." });
    }
  };

  handleBookCourseSuccess = ({ data }: any) => {
    this.setState({ bookCourseLoading: false });
    if (data.success) {
      this.setState({ errorMsg: null });
      if (data.checkoutUrl) {
        window.location.href = data.checkoutUrl;
      } else {
        window.location.href = `${window.location.origin}/club/${this.state.clubId}/branch/${this.state.branchId}/courseBooking/${data.courseBookingId}`;
        this.requestCourse();
      }
    } else {
      this.setState({ errorMsg: data.errorMsgDe });
      if (data.code === "user-data-needed") {
        if (this.state.redirectTo === "branchCourse") {
          const singleCourseUrls = `/club/${this.state.clubId}/branchCourse/${this.state.branchId}/${this.state.courseId}`;
          this.setState({
            redirect: `/club/${this.state.clubId}/profile?redirect=${singleCourseUrls}&complete=true`,
          });
        } else {
          const singleCourseUrls = `/club/${this.state.clubId}/branch/${this.state.branchId}/course/${this.state.courseId}`;
          this.setState({
            redirect: `/club/${this.state.clubId}/branch/${this.state.branchId}/profile?redirect=${singleCourseUrls}&complete=true`,
          });
        }
      }
    }
  };

  handleEmailChange = (val: any) => {
    this.setState({ email: val });
  };

  handleFNameChange = (val: any) => {
    this.setState({ fName: val });
  };

  handleLNameChange = (val: any) => {
    this.setState({ lName: val });
  };

  handlePhoneNumberChange = (val: any) => {
    this.setState({ phoneNumber: val });
  };

  handleCityChange = (val: any) => {
    this.setState({ city: val });
  };

  handleHouseNumberChange = (val: any) => {
    this.setState({ houseNumber: val });
  };

  handleZipcodeChange = (val: any) => {
    this.setState({ zipcode: val });
  };

  handleStreetChange = (val: any) => {
    this.setState({ street: val });
  };

  handleBirthDateChange = (val: any) => {
    this.setState({ birthDate: val.target.value });
  };

  toggleTermsAccepted = () => {
    this.setState({ termsAccepted: !this.state.termsAccepted });
  };

  render() {
    if (
      !this.state.branchLoaded ||
      !this.state.courseLoaded ||
      !this.context.userLoaded
    ) {
      return (
        <>
          <div className="loading-container">
            <HashLoader color={"#c31924"} size={100} loading={true} />
          </div>
        </>
      );
    }

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const testMode = this.state.branch?.billing?.mode !== "live";

    const cancellationDate =
      this.state.course.cancellationDays === null
        ? null
        : addDayToDate(
            firebaseDateToDateObj(this.state.course.startTime),
            -this.state.course.cancellationDays
          );

    const [startDate, initialPrice] = calcSubscription(
      this.state.course.price,
      firebaseDateToDateObj(this.state.course.startTime)
    );

    const bookableFromDate = firebaseDateToDateObj(
      this.state.course.bookableDate
    );
    const bookableFrom = bookableFromDate
      ? bookableFromDate > new Date()
        ? bookableFromDate
        : null
      : null;

    const branchRoleObj = this.state.branch?.roles
      ? this.state.branch.roles
      : {};
    branchRoleObj["default"] = {
      name: "Regulärer Preis",
      priority: -2,
    };
    branchRoleObj["public"] = {
      name: "Registrierter Benutzer Preis",
      priority: -1,
    };

    const alternativePriceArr: Array<any> = (
      this.state.course?.priceArr ? this.state.course.priceArr : []
    )
      .filter(
        (currPriceElem: any) =>
          (currPriceElem.showAlways ||
            currPriceElem.id === this.state.course.priceRole) &&
          branchRoleObj[currPriceElem.id]?.name
      )
      .map((currPriceElem: any) => {
        currPriceElem.roleName = branchRoleObj[currPriceElem.id]?.name;
        currPriceElem.priority = branchRoleObj[currPriceElem.id]?.priority;
        currPriceElem.used = currPriceElem.id === this.state.course.priceRole;
        return currPriceElem;
      });

    alternativePriceArr.sort((a: any, b: any) =>
      a.priority < b.priority ? 1 : -1
    );

    return (
      <>
        <div className="subscreen-main-container">
          <div className="subscreen-box-container">
            <BackElement
              text="zurück zur Kursauswahl"
              to={
                this.state.redirectTo === "branchCourse"
                  ? `/club/${this.state.clubId}/course`
                  : `/club/${this.state.clubId}/branch/${this.state.branchId}/course`
              }
            />
            <Title title="Kurs buchen" />
            <TestMode active={this.state.branch?.billing?.mode !== "live"} />
            <div className="courseBooking-course-info-container">
              <div>
                {!this.state.course.imgLowRes ? (
                  <div className="single-course-img">
                    <FontAwesomeIcon
                      icon={["fas", "image"]}
                      size="6x"
                      className="image-icon"
                    />
                  </div>
                ) : (
                  <img
                    className="single-course-img"
                    src={this.state.course.imgLowRes}
                    alt="Kursbild"
                  />
                )}
              </div>
              <div>
                {this.state.course.courseType === "single-course" && (
                  <>
                    <h2>{this.state.course.title}</h2>
                    <span>{this.state.course.description}</span>
                    <div className="course-text-container">
                      Wann:{" "}
                      <span className="normal-weight">
                        {dateDurationToFullStr(
                          firebaseDateToDateObj(this.state.course.startTime),
                          firebaseDateToDateObj(this.state.course.endTime)
                        )}
                      </span>
                    </div>
                    {this.state.course?.location?.name && (
                      <div className="course-text-container">
                        Wo:{" "}
                        <span className="normal-weight">
                          {this.state.course?.location?.name}
                        </span>
                      </div>
                    )}
                    <div className="course-text-container">
                      Freie Plätze:{" "}
                      <span className="normal-weight">
                        {testMode
                          ? this.state.course.freePlacesTest
                          : this.state.course.freePlaces}
                      </span>
                    </div>
                    <div className="course-text-container">
                      Preis:{" "}
                      {alternativePriceArr.length <= 1 ? (
                        <span className="normal-weight">
                          {priceToStr(this.state.course.price)}€
                        </span>
                      ) : (
                        <div className="course-text-container">
                          <table className="alternative-price-table">
                            <tbody>
                              {alternativePriceArr.map(
                                (price: any, index: number) => (
                                  <tr
                                    key={index}
                                    className={`${
                                      price.used ? "alternative-price-used" : ""
                                    }`}
                                  >
                                    <td>
                                      {price.roleName}
                                      {price.used ? " (Dein Preis)" : ""}:
                                    </td>
                                    <td>{priceToStr(price.price)}€</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {this.state.course.courseType === "block-course" && (
                  <>
                    <h2>{this.state.course.title}</h2>
                    <span>{this.state.course.description}</span>
                    <div className="course-text-container">
                      Wann:{" "}
                      <span className="normal-weight">
                        {this.state.course.courses.map(
                          (course: any, index: number) => (
                            <div key={index}>
                              -{" "}
                              {dateDurationToFullStr(
                                firebaseDateToDateObj(course.startTime),
                                firebaseDateToDateObj(course.endTime)
                              )}{" "}
                              {course?.location?.name && (
                                <> ({course.location.name})</>
                              )}
                            </div>
                          )
                        )}
                      </span>
                    </div>

                    <div className="course-text-container">
                      Freie Plätze:{" "}
                      <span className="normal-weight">
                        {testMode
                          ? this.state.course.freePlacesTest
                          : this.state.course.freePlaces}
                      </span>
                    </div>
                    <div className="course-text-container">
                      Preis:{" "}
                      {alternativePriceArr.length <= 1 ? (
                        <span className="normal-weight">
                          {priceToStr(this.state.course.price)}€
                        </span>
                      ) : (
                        <div className="course-text-container">
                          <table className="alternative-price-table">
                            <tbody>
                              {alternativePriceArr.map(
                                (price: any, index: number) => (
                                  <tr
                                    key={index}
                                    className={`${
                                      price.used ? "alternative-price-used" : ""
                                    }`}
                                  >
                                    <td>
                                      {price.roleName}
                                      {price.used ? " (Dein Preis)" : ""}:
                                    </td>
                                    <td>{priceToStr(price.price)}€</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {this.state.course.courseType === "infinity-course" && (
                  <>
                    <h2>{this.state.course.title}</h2>
                    <span>{this.state.course.description}</span>
                    <div className="course-text-container">
                      Wann:{" "}
                      <span className="normal-weight">
                        jeden {weekDays[this.state.course.weekDay].name} um{" "}
                        {this.state.course.time} Uhr
                      </span>
                    </div>
                    {this.state.course.negativeDates &&
                      this.state.course.negativeDates.length > 0 && (
                        <div className="course-text-container">
                          Ausfallende Termine:{" "}
                          <span className="normal-weight">
                            {this.state.course.negativeDates
                              .filter((dateStr: string) => {
                                const date = strToDate(dateStr);
                                const dateAdded = addDayToDate(new Date(), -1);
                                return date > (dateAdded ? dateAdded : 0);
                              })
                              .sort((a: any, b: any) =>
                                strToDate(a).getTime() > strToDate(b).getTime()
                                  ? 1
                                  : -1
                              )
                              .map((date: string, index: number) => (
                                <div key={index}>- {date} </div>
                              ))}
                          </span>
                        </div>
                      )}
                    {this.state.course?.location?.name && (
                      <div className="course-text-container">
                        Wo:{" "}
                        <span className="normal-weight">
                          {this.state.course?.location?.name}
                        </span>
                      </div>
                    )}
                    <div className="course-text-container">
                      Freie Plätze:{" "}
                      <span className="normal-weight">
                        {testMode
                          ? this.state.course.freePlacesTest
                          : this.state.course.freePlaces}
                      </span>
                    </div>
                    <div className="course-text-container">
                      Preis:{" "}
                      {alternativePriceArr.length <= 1 ? (
                        <span className="normal-weight">
                          {priceToStr(this.state.course.price)}€
                        </span>
                      ) : (
                        <div className="course-text-container">
                          <table className="alternative-price-table">
                            <tbody>
                              {alternativePriceArr.map(
                                (price: any, index: number) => (
                                  <tr
                                    key={index}
                                    className={`${
                                      price.used ? "alternative-price-used" : ""
                                    }`}
                                  >
                                    <td>
                                      {price.roleName}
                                      {price.used ? " (Dein Preis)" : ""}:
                                    </td>
                                    <td>{priceToStr(price.price)}€</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {this.context.user && (
                  <>
                    <CourseTerms
                      single={
                        this.state.course?.courseType === "single-course" ||
                        this.state.course?.courseType === "block-course"
                      }
                      termsAccepted={this.state.termsAccepted}
                      toggleTermsAccepted={this.toggleTermsAccepted}
                      initialPrice={initialPrice}
                      price={this.state.course.price}
                      startDate={startDate}
                      noticePeriod={this.state.course.noticePeriod}
                      noticePeriodDateUnit={
                        this.state.course.noticePeriodDateUnit
                      }
                      cancellationDays={this.state.course.cancellationDays}
                      cancellationDate={cancellationDate}
                    />

                    <ErrorMessage message={this.state.errorMsg} />
                    <SecButton
                      change={true}
                      color="green"
                      loading={this.state.bookCourseLoading}
                      onClick={this.bookCourse}
                      title={
                        this.state.course?.courseType === "single-course" ||
                        this.state.course?.courseType === "block-course"
                          ? bookableFrom
                            ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                            : "Kurs kostenpflichtig buchen"
                          : bookableFrom
                          ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                          : "Abonnement starten"
                      }
                      className={`course-book-btn${
                        bookableFrom ? " course-book-btn-gray" : ""
                      }`}
                    />
                  </>
                )}
              </div>
            </div>
            {!this.context.user && (
              <div>
                <div className="medium-space"></div>
                <SubTitle title="Name" />
                <div className="course-time-input-container">
                  <Input
                    value={this.state.fName}
                    onChange={this.handleFNameChange}
                    placeholder="Vorname"
                  />
                  <Input
                    value={this.state.lName}
                    onChange={this.handleLNameChange}
                    placeholder="Nachname"
                  />
                </div>
                <SubTitle title="E-Mail" />
                <Input
                  value={this.state.email}
                  onChange={this.handleEmailChange}
                  placeholder="E-Mail"
                />
                {this.state.branch?.settings?.gAddress && (
                  <>
                    <SubTitle title="Adresse" />
                    <div className="course-time-input-container">
                      <Input
                        value={this.state.street}
                        onChange={this.handleStreetChange}
                        placeholder="Straße"
                        className="street-input"
                      />
                      <Input
                        value={this.state.houseNumber}
                        onChange={this.handleHouseNumberChange}
                        placeholder="Nr."
                        className="house-number-input"
                      />
                    </div>
                    <div className="profile-location-container">
                      <Input
                        value={this.state.zipcode}
                        onChange={this.handleZipcodeChange}
                        placeholder="PLZ"
                        className="zipcode-input"
                      />
                      <Input
                        value={this.state.city}
                        onChange={this.handleCityChange}
                        placeholder="Stadt"
                        className="city-input"
                      />
                    </div>
                  </>
                )}
                {this.state.branch?.settings?.gPhoneNumber && (
                  <>
                    <SubTitle title="Telefonnummer" />
                    <Input
                      value={this.state.phoneNumber}
                      onChange={this.handlePhoneNumberChange}
                      placeholder="Telefonnummer"
                    />
                  </>
                )}
                {this.state.branch?.settings?.gBirthDate && (
                  <>
                    <SubTitle title="Geburtsdatum" />
                    <input
                      type="date"
                      className="course-input"
                      value={this.state.birthDate}
                      onChange={this.handleBirthDateChange}
                    />
                  </>
                )}

                <div className="medium-space"></div>
                <CourseTerms
                  single={
                    this.state.course?.courseType === "single-course" ||
                    this.state.course?.courseType === "block-course"
                  }
                  termsAccepted={this.state.termsAccepted}
                  toggleTermsAccepted={this.toggleTermsAccepted}
                  initialPrice={initialPrice}
                  price={this.state.course.price}
                  startDate={startDate}
                  noticePeriod={this.state.course.noticePeriod}
                  noticePeriodDateUnit={this.state.course.noticePeriodDateUnit}
                  cancellationDays={this.state.course.cancellationDays}
                  cancellationDate={cancellationDate}
                />
                <ErrorMessage message={this.state.errorMsg} />
                <SecButton
                  change={true}
                  color="green"
                  loading={this.state.bookCourseLoading}
                  onClick={this.bookCourse}
                  title={
                    this.state.course?.courseType === "single-course" ||
                    this.state.course?.courseType === "block-course"
                      ? bookableFrom
                        ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                        : "Kurs kostenpflichtig als Gast buchen"
                      : bookableFrom
                      ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                      : "Abonnement starten"
                  }
                  className={`course-book-btn${
                    bookableFrom ? " course-book-btn-gray" : ""
                  }`}
                />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
