import React from "react";
import "./CourseElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import { weekDays } from "../services/constants";

export default class InfinityCourseElementManage extends React.Component<
  {
    clubId: string;
    branchId: string;
    courseId: string;
    title: string;
    description: string;
    when: string;
    where: string;
    freePlaces: number;
    price: string;
    imgUrl: string;
    editable: boolean;
    viewable: boolean;
    weekDay: number;
    deleted: boolean;
  },
  {}
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div
          className={`course-container ${
            this.props.deleted ? " grayscale" : ""
          }`}
        >
          {(this.props.editable || this.props.viewable) && (
            <div className="course-edit-icons">
              <Link
                to={`/club/${this.props.clubId}/branch/${this.props.branchId}/participants/${this.props.courseId}`}
                className="black"
              >
                <FontAwesomeIcon
                  icon={["fas", "users"]}
                  size="lg"
                  onClick={() => {}}
                  className="course-settings-icon"
                />
              </Link>
              {this.props.editable && (
                <Link
                  to={`/club/${this.props.clubId}/branch/${this.props.branchId}/edit-course/${this.props.courseId}`}
                  className="black"
                >
                  <FontAwesomeIcon
                    icon={["fas", "edit"]}
                    size="lg"
                    onClick={() => {}}
                    className="course-settings-icon"
                  />
                </Link>
              )}
            </div>
          )}
          <div
            className={`inner-course-container ${
              this.props.editable ? "inner-course-container-editable" : ""
            }`}
          >
            {!this.props.imgUrl ? (
              <div className="course-img">
                <FontAwesomeIcon
                  icon={["fas", "image"]}
                  size="6x"
                  className="image-icon"
                />
              </div>
            ) : (
              <img
                className="course-img"
                src={this.props.imgUrl}
                alt="Kursbild"
              />
            )}
          </div>
          <div className="course-info-container">
            <div className="course-info-inner-container">
              <div className="course-title-container">{this.props.title}</div>
              <div>{this.props.description}</div>
              <div className="course-text-container">
                Wann:{" "}
                <span className="normal-weight">
                  jeden {weekDays[this.props.weekDay].name}
                </span>
              </div>
              {this.props.where && (
                <div className="course-text-container">
                  Wo: <span className="normal-weight">{this.props.where}</span>
                </div>
              )}
              <div className="course-text-container">
                Freie Plätze:{" "}
                <span className="normal-weight">{this.props.freePlaces}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
