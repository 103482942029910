import React from "react";
import "./CourseElement.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { MainContext } from "../contexts/MainContext";
import { dateToFullStr, firebaseDateToDateObj } from "../functions/utils";

export default class BlockCourseElement extends React.Component<
  {
    clubId: string;
    branchId: string;
    courseId: string;
    title: string;
    description: string;
    when: string;
    courses: Array<any>;
    freePlaces: number;
    price: string;
    imgUrl: string;
    bookableFrom: any;
  },
  { showMore: boolean }
> {
  static contextType = MainContext;
  constructor(props: any) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  toggleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  render() {
    const bookableFrom = this.props.bookableFrom
      ? this.props.bookableFrom > new Date()
        ? this.props.bookableFrom
        : null
      : null;
    return (
      <>
        <div className={"course-container"}>
          <div>
            {!this.props.imgUrl ? (
              <div className="course-img">
                <FontAwesomeIcon
                  icon={["fas", "image"]}
                  size="6x"
                  className="image-icon"
                />
              </div>
            ) : (
              <img
                className="course-img"
                src={this.props.imgUrl}
                alt="Kursbild"
              />
            )}
          </div>
          <div className="course-info-container">
            <div className="course-info-inner-container">
              <div className="course-title-container">{this.props.title}</div>
              <div>{this.props.description}</div>
              <div className="course-text-container">
                Termine:{" "}
                <span className="normal-weight">
                  {this.props.courses.length}
                </span>
              </div>
              <div className="course-text-container">
                Wann:{" "}
                <span className="normal-weight">
                  {this.props.courses
                    .slice(
                      0,
                      this.state.showMore ? this.props.courses.length : 3
                    )
                    .map((course: any, index: number) => (
                      <div key={index}>
                        -{" "}
                        {dateToFullStr(firebaseDateToDateObj(course.startTime))}
                      </div>
                    ))}
                </span>
                {this.props.courses.length > 3 && (
                  <div
                    className="more-info-container"
                    onClick={this.toggleShowMore}
                  >
                    <div className="more-info-inner-container">
                      {!this.state.showMore ? (
                        <FontAwesomeIcon
                          icon={["fas", "chevron-down"]}
                          size="sm"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={["fas", "chevron-up"]}
                          size="sm"
                        />
                      )}
                    </div>
                    {this.state.showMore ? "weniger Anzeigen" : "mehr Anzeigen"}
                  </div>
                )}
              </div>
              <div className="course-text-container">
                Freie Plätze:{" "}
                <span className="normal-weight">{this.props.freePlaces}</span>
              </div>
              <div className="course-text-container">
                Preis:{" "}
                <span className="normal-weight">{this.props.price}€</span>
              </div>
            </div>
            <div>
              <Link
                to={`/club/${this.props.clubId}/branch/${this.props.branchId}/course/${this.props.courseId}`}
                className={`course-book-btn no-underline${
                  bookableFrom ? " course-book-btn-gray" : ""
                }`}
              >
                {bookableFrom
                  ? `Buchbar ab ${dateToFullStr(bookableFrom)}`
                  : this.context.userData
                  ? "Kurs Buchen"
                  : "Kurs als Gast buchen"}
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}
