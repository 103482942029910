import React from "react";
import "./TextCheckbox.css";

export default class TextCheckbox extends React.Component<
  {
    val: boolean;
    onChange: any;
    text?: string;
  },
  {}
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="text-checkbox-container">
        <input
          type="checkbox"
          className="accept-container-checkbox"
          checked={this.props.val}
          onClick={this.props.onChange}
        />
        {this.props.text ? this.props.text : ""}
      </div>
    );
  }
}
